import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Comment from '../components/Comment'
import { Container, Title } from './post-styles';
import Share from '../components/share';

import { FiCalendar } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { FiTag } from "react-icons/fi";

import TagBadgeList from './../components/TagBadgeList';

function formatDate(date) {
  var monthNames = [
    "janúar", "febrúar", "mars",
    "apríl", "maí", "júní", "júlí",
    "ágúst", "september", "október",
    "nóvember", "desember"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + '. ' + monthNames[monthIndex] + ' ' + year;
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const author = post.frontmatter.author;
    const date = new Date(post.frontmatter.date);

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Container>
        
            <Title>{post.frontmatter.title}</Title>
            <sub
              css={`
                color: rgba(0, 0, 0, 0.8);
                font-size: 1.1em
              `}
            >
              <span> <FiCalendar size={20} />&nbsp;{ 
                formatDate(date)
              }
              </span>
              <span>&nbsp; &nbsp;</span><br />
              <span> <FiUser size={20} />&nbsp;{post.frontmatter.author}</span>
              <span>&nbsp; &nbsp;</span><br />
              <span>
              <FiTag size={20} />&nbsp;<TagBadgeList tags={post.frontmatter.tags} />
        </span>
            </sub>
         
          <div
            css={`
              margin: 5rem 0;
            `}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
      <Share
            post={{
              title: post.frontmatter.title,
              excerpt: post.excerpt,
              author: author,
            }}
          />
          <Comment slug={post.frontmatter.slug} /> 
        </Container>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt
      html
     
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        slug
        category
        tags
      }
    }
  }
`;
