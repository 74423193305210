import React from "react";
import PropTypes from "prop-types";
import { FacebookProvider, Comments } from "react-facebook";

import { facebookAppId, siteUrl, locale } from "../../siteConfig.js";

const Comment = props => {
  const { slug } = props;

  return (
   
    <React.Fragment>
      <div id="post-comments" className="comments" width="100%">
     <FacebookProvider appId={facebookAppId} language={locale}>
          <Comments href={`${siteUrl}${slug}`} width="100%" colorscheme="light" />
        </FacebookProvider>
     
      </div>
    </React.Fragment>
  );
};

Comment.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Comment;