import React from 'react'
import PropTypes from 'prop-types';
import TagBadge from './TagBadge';

class TagBadgeList extends React.Component {
  render() {
    const { tags } = this.props

    return (
      tags.map((tag, i) => {
        return (
          <span key={tag}>
            &nbsp;<TagBadge tag={tag} />
              
          </span>
        )
      })
      
    )
  }
}

TagBadgeList.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default TagBadgeList
