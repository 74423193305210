const path = require(`path`);

module.exports = {
  siteTitle: 'Hjólreiðadeild Breiðabliks - Algengar spurningar',
  siteDescription: 'Hjólreiðadeild Breiðabliks algengar spurningar',
  multilangPosts: true, // enable/disable flags in post lists
  siteUrl: 'https://breidablik.bike/',
  googleAnalyticsId: 'UA-132815827-1',
  facebookAppId: '530499170775865',
  icon: `src/images/breidabliklogo.png`,
  locale: 'is_IS',
};
